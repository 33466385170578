import { regexIndexOf } from './regexIndexOf';

export const truncateText = (text: string, num = 256): string => {
  if (text.length < num) {
    return text;
  }

  let tempText = text
    .slice(0, regexIndexOf(text, new RegExp('\\s|\\.|\\,'), num))
    .trimEnd();

  if ([',', '.'].includes(tempText[tempText.length - 1]))
    tempText = tempText.slice(0, -1);

  return `${tempText}...`;
};
