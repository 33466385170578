/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Text } from 'theme-ui';
import { Link } from 'gatsby';
import InnerHTML from 'dangerously-set-html-content';
import PortableText from '../../custom/portableText';
import { TwitterCarouselItem } from '../../organisms/TwitterCarousel/types';
import {
  ICarouselItem,
  IInfoBannerPopup,
  ITeamMember,
} from '../../../apiTypes';
import {
  headingListitems,
  headingText,
  ICaseStudiesSection,
  IInsightsSection,
  IRelatedPage,
  IStickyListSection,
  IGetCarouselQuotes,
  IContactCard,
  IPersonInfo,
  IMapList,
  IPositions,
  TwitterQuery,
  ISlide,
} from './types';
import { WorkListingItem } from '../../organisms/WorkListing/types';
import { createItem } from '../work/utils';
import { ArticlesCarouselItem } from '../../organisms/ArticlesCarousel/types';
import routes from '../../../routes';
import { generatePath } from '../../../utils/generatePath';
import { InfoGridItem, InfoGridProps } from '../../molecules/InfoGrid/types';
import { RelatedPageItem } from '../../organisms/RelatedPages/types';
import PlainTextWithHighlight from '../../custom/PlainTextWithHighlight';
import { InfoBoxListItem } from '../../organisms/InfoBoxList/types';
import { JobShort } from '../join/types';
import {
  ContactPageBoxContentItem,
  ContactPageBoxItem,
} from '../contact/partials/BoxList/types';
import { MapBoxListItem } from '../../organisms/MapBoxList/types';
import { InfoBannerItem } from '../../organisms/InfoBanner/types';
import { DismissableSectionItem } from '../../organisms/DismissableSection/types';
import { convertNewLinesToBreakLines } from '../../../utils/convertNewLinesToBreakLines';

export const getCarouselItems = (
  items: ICarouselItem[] = [],
): TwitterCarouselItem[] => {
  const carouselItems = items.map((item) => ({
    author: item.heading,
    content: <PortableText blocks={item._rawBody} />,
    key: item._key,
  }));
  return carouselItems;
};

export const getWorkItems = (
  list: ICaseStudiesSection['caseStudies'] = [],
): WorkListingItem[] => {
  const workItems = list.map((work) => createItem(work));

  return workItems;
};

const regexUrlPath = RegExp(
  `/hubfs/${process.env.GATSBY_HUBSPOT_PORTAL_ID}/`,
  'gi',
);

export const getImageUrl = (originalUrl: string, regex: RegExp): string =>
  regex.test(originalUrl)
    ? `${originalUrl.replace(
        regex,
        `/hub/${process.env.GATSBY_HUBSPOT_PORTAL_ID}/hubfs/`,
      )}?width=200&length=300`
    : originalUrl;

export const getInsightsItems = (
  list: IInsightsSection['insights'] = [],
): ArticlesCarouselItem[] => {
  const insightsItems = list.map((insight) => {
    const src = getImageUrl(insight.imageUrl, regexUrlPath);

    // for preview we fetch the date from sanity and there is no format methods
    // we have to do it manually
    const formattedDate = insight.date.replace(/-/g, '.');

    return {
      tag: `${insight.author} / ${formattedDate}`,
      title: insight.title,
      to: generatePath(routes.INSIGHT_ARTICLE, {
        slug: insight.slug.current,
      }),
      description: insight.description,
      image: { src },
      key: insight.id,
    };
  });

  return insightsItems;
};

export const getStickyListItems = (
  list: headingText[] = [],
): InfoGridItem[] => {
  const items = list.map((item) => ({
    title: item.title,
    content: item.text || '',
    key: item._key,
  }));

  return items;
};
export const getListsWithSublistsItems = (
  list: headingListitems[] = [],
): InfoGridItem[] => {
  const items = list.map((item) => ({
    title: item.heading,
    content: item.list || [],
    key: item._key,
  }));

  return items;
};
export const getRelatedPagesItems = (
  list: IRelatedPage[] = [],
): RelatedPageItem[] => {
  const items = list.map((item) => ({
    image: {
      fluid: item?.image?.asset?.fluid,
    },
    isWorkRelated: item.isWorkRelated,
    to: item?.url?.slug?.current,
    title: <PlainTextWithHighlight blocks={item._rawBody} />,
    ctaText: item.buttonText,
    key: item._key,
  }));

  return items;
};

export const getFirstWord = (text: string): string => {
  if (!text) {
    return '';
  }
  return text.split(' ')[0];
};

export const getMembers = (members: ITeamMember[] = []): InfoGridItem[] => {
  const list = members.map((member) => ({
    title: getFirstWord(member.name),
    content: member.position,
    key: member.id,
    modal: {
      name: getFirstWord(member.name),
      profession: member.position,
      email: member.email,
      // eslint-disable-next-line no-underscore-dangle
      description: <PortableText blocks={member._rawDescription} />,
      image: {
        fluid: member?.mainImage?.asset?.fluid,
      },
    },
  }));

  return list;
};
export const getMediaItem = (
  items: IStickyListSection['mediaItem'] = [],
): InfoGridProps['media'] => {
  // I'm taking the first one because it can be an array with only one element there
  const mediaItem = items?.[0];
  if (mediaItem) {
    if (mediaItem._type === 'image') {
      return {
        fluid: mediaItem?.asset?.fluid,
      };
    }
    const url =
      mediaItem._type === 'video'
        ? mediaItem?.video?.asset?.url
        : mediaItem?.videoUrl;

    return {
      url,
      image: mediaItem?.placeholderImage?.asset?.fluid?.src,
    };
  }

  return undefined;
};

export const getCarouselQuotes: IGetCarouselQuotes = (item) => {
  const items = item?.quotes?.map((quote) => ({
    image: {
      fluid: quote?.image?.asset?.fluid,
    },
    title: quote?.title,
    name: quote?.authorName,
    profession: quote?.positionName,
    quote: quote?.quote,
    key: quote._key,
  }));
  return {
    items,
    title: item?.heading,
  };
};

export const getJobs = (jobs: JobShort[] = []): InfoBoxListItem[] =>
  jobs.map((job) => ({
    title: job.name,
    description: job.description,
    to: generatePath(routes.JOB, {
      slug: job.slug.current,
    }),
    ctaLabel: 'Find out more',
    key: job.id,
  }));

const createPersonInfo = (
  items: IPersonInfo[] = [],
): ContactPageBoxContentItem[] =>
  items.map((item) => ({
    title: item.title,
    name: item.name,
    profession: item.profession,
    email: item.email,
    phoneNumber: item.phoneNumber,
    zoomLink: '',
    key: item._key,
  }));

export const getContactCards = (
  cards: IContactCard[] = [],
): ContactPageBoxItem[] => {
  const items = cards.map((card) => ({
    title: card.title,
    key: card._key,
    items: createPersonInfo(card.personInfo),
  }));

  return items;
};

const positionsList = (positions?: IPositions): string[] => {
  // create an object with values, because the order of key is important
  const values: IPositions = {
    mobile: positions?.mobile || 0,
    tablet: positions?.tablet || 0,
    desktop: positions?.desktop || 0,
  };

  return Object.values(values).map((position) => `${position || 0}%`);
};

const textToArray = (text: string): string[] => text.split('\n');

export const getMapListItems = (list: IMapList[] = []): MapBoxListItem[] => {
  const items = list.map((item) => ({
    title: item.title || '',
    id: item.title || '',
    address: textToArray(item?.address || ''),
    phoneNumber: item.phoneNumber,
    zoomLink: item?.zoom && `${item.zoom}`,
    href: item.mapUrl || '',
    pinPercentagePosition: {
      y: positionsList(item.pinPositionY),
      x: positionsList(item.pinPositionX),
    },
    map: {
      fluid: item.image?.asset?.fluid,
    },
    key: item._key,
  }));

  return items;
};

export const prepareInfoBannerPopup = (
  infoBanner: IInfoBannerPopup[] = [],
  textVariant = 'h4roman',
): InfoBannerItem[] =>
  infoBanner.map((banner) => ({
    key: '1',
    title: banner.title,
    content: (
      <Text as="p" variant={textVariant} mb={['1rem', '1rem', '2.5rem']}>
        {banner.quote}
      </Text>
    ),
  }));

export const getTwitterItems = (
  items: TwitterQuery['tweets']['nodes'] = [],
): TwitterCarouselItem[] =>
  items.map((item) => ({
    author: `@${item.user.screen_name}`,
    content: item.full_text,
    link: `${process.env.GATSBY_TWITTER_STATUS_URL}${item.id_str}`,
    key: item.id_str,
  }));

export const prepareSlides = (
  slides: ISlide[] = [],
): DismissableSectionItem[] =>
  slides.map((slide) => ({
    key: slide._key,
    category: slide.tag,
    content: (
      <>
        <h3>{slide.title}</h3>
        <p>
          <InnerHTML html={convertNewLinesToBreakLines(slide.description)} />
        </p>
        {slide.buttonLink && slide.buttonText ? (
          <Link to={slide.buttonLink} sx={{ variant: 'links.underlined' }}>
            {slide.buttonText}
          </Link>
        ) : null}
      </>
    ),
    imageSrc: slide?.image?.asset?.fluid?.src,
    imageAlt: 'Slide image',
    whiteColorText: slide?.setWhiteColorText,
  }));
