import React from 'react';
import { Box } from 'theme-ui';
import { PageSectionProps } from './types';

const PageSection: React.FC<PageSectionProps> = ({
  as = 'section',
  hasSmallSpacing,
  children,
  ...props
}) => (
  <Box
    {...{ as, ...props }}
    variant={
      hasSmallSpacing ? 'pages.section.wrapperSmall' : 'pages.section.wrapper'
    }
  >
    {children}
  </Box>
);

export default PageSection;
