/* eslint-disable no-underscore-dangle */
import React, { useCallback } from 'react';

import Highlight from '../atoms/Highlight';

type IProps = {
  blocks: IContent[];
};

const PlainTextWithHighlight: React.FC<IProps> = ({ blocks }) => {
  const renderContent = useCallback(() => {
    let delay = 1;

    return (blocks || []).map((block, index) => {
      const TagName =
        block.style === 'normal'
          ? 'p'
          : (block.style as keyof JSX.IntrinsicElements) || 'span';

      return (
        <TagName key={block._key} className={block.style === 'p' ? 'alt' : ''}>
          {block._type !== 'block' || !block.children ? (
            <></>
          ) : (
            <>
              {block.children.map((blockText) => (
                <React.Fragment key={blockText._key}>
                  {blockText.marks.includes('mark') ? (
                    // eslint-disable-next-line no-plusplus
                    <Highlight custom={delay++}>{blockText.text}</Highlight>
                  ) : (
                    <>{blockText.text}</>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
          {index < blocks.length - 1 && (
            <>
              <br />
              <br />
            </>
          )}
        </TagName>
      );
    });
  }, [blocks]);

  return <>{renderContent()}</>;
};

export default PlainTextWithHighlight;
