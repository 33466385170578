/** @jsx jsx */
import { AspectRatio, Box, Flex, jsx, Text } from 'theme-ui';
import { Link } from 'gatsby';
import { IconThunderstrike } from '../../../assets/svg';
import { ArticleTileProps } from './types';
import Image from '../../atoms/Image';
import { truncateText } from '../../../utils/truncateText';

const ArticleTile: React.FC<ArticleTileProps> = ({
  tag,
  title,
  description,
  image,
  to,
  isFeatured,
}) => (
  <Link {...{ to }} sx={{ variant: 'articleTile.wrapper' }}>
    <Flex className="media" variant="articleTile.media">
      <AspectRatio ratio={382 / 192}>
        <Box className="media-content" variant="articleTile.mediaContent">
          <Image {...image} />
        </Box>
      </AspectRatio>
      <IconThunderstrike
        sx={{ variant: 'articleTile.media.icon' }}
        className="icon"
      />
    </Flex>
    <Box variant="articleTile.details">
      <Text as="span" variant="articleTile.tag" mt="1rem">
        {tag}
      </Text>
      <Text
        as="h3"
        variant={isFeatured ? 'h2' : 'h6'}
        mt={isFeatured ? ['1rem', '', '2rem'] : ['0.5rem', '', '1rem']}
      >
        {title}
      </Text>
      <Text
        as="p"
        variant="smallBody"
        mt={['0.5rem', '', '1rem']}
        sx={{ maxWidth: '40.125rem' }}
      >
        {truncateText(description, isFeatured ? 230 : 130)}
      </Text>
    </Box>
  </Link>
);

export default ArticleTile;
