import { ICasyStudyThumbnail, ICategory } from '../../../apiTypes';
/* eslint-disable no-underscore-dangle */
import routes from '../../../routes';
import { generatePath } from '../../../utils/generatePath';
import { PageFilterItem } from '../../molecules/PageFilters/types';
import { WorkListingItem } from '../../organisms/WorkListing/types';

import { WorkPageProps } from './types';

export const createItem = (
  caseStudy: ICasyStudyThumbnail,
  small = false,
): WorkListingItem => ({
  category: caseStudy?.clientName,
  title: caseStudy?.title,
  to: generatePath(routes.CASE_STUDY_SINGLE, {
    slug: caseStudy?.slug?.current || '-',
  }),
  image: {
    fluid: caseStudy?.mainImage?.asset?.fluid,
  },
  video: {
    url: caseStudy?.mainVideo?.asset?.url,
    image: caseStudy?.mainImage?.asset?.fluid,
  },
  small,
  dark: caseStudy?.darkBackground,
  key: caseStudy?.id,
});

export const getWorkItems = (
  data: WorkPageProps['data']['works']['caseStudies'] = [],
): WorkListingItem[] => {
  const items: WorkListingItem[] = data
    .map((section) => {
      const caseStudy = section.caseStudy?.[0];
      if (section._type === 'singleCaseStudy') {
        return [createItem(caseStudy)];
      }

      const secondcaseStudy = section.caseStudySecond?.[0];

      return [createItem(caseStudy, true), createItem(secondcaseStudy, true)];
    })
    .flat();

  return items;
};

export const getWorkFilters = (list: ICategory[]): PageFilterItem[] => {
  const filters = list.map((item) => ({
    label: item.title,
    to:
      item.slug.current === '/'
        ? routes.WORK
        : `${routes.WORK_CATEGORY}${item.slug.current}`,
    key: item.id,
  }));

  return filters;
};

const getDrafts = (categories: ICategory[], pattern = 'drafts.'): string[] =>
  categories
    .filter(({ id }) => id.startsWith(pattern))
    .map(({ id }) => id.replace(pattern, ''));

export const getFilteredCategories = (categories: ICategory[]): ICategory[] => {
  const drafts = getDrafts(categories);
  const filtered = categories.filter(({ id }) => !drafts.includes(id));

  return filtered;
};
